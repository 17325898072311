export class Notification {
  constructor(notificationObj) {
    if(typeof notificationObj === 'undefined' || notificationObj === null){
      notificationObj = {};
    }

    this.id         = notificationObj.id || null;
    this.partner_id = notificationObj.partner_id || null;
    this.title      = notificationObj.title || null;
    this.body       = notificationObj.body || null;
    this.entity     = notificationObj.entity || null;
    this.created_at = new Date(notificationObj.created_at || null);
    this.readed     = false;

    if(typeof notificationObj.status !== "undefined"){
      this.readed = notificationObj.status.length > 0;
    }
  }

  static init(notificationObj) {
    return new Notification(notificationObj);
  }
}

